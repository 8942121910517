import * as React from "react"
import styled from "styled-components"
import { FC, useContext, useState } from "react"
import { Search } from "semantic-ui-react"
import { downloadReport, generateAdhocReportData } from "../../../api/platform/reportApi"
import {
  api,
  Checkbox,
  CloseIcon,
  colors,
  LoginContext,
  Spinner,
} from "@social-supermarket/social-supermarket-components"
import { PrimaryButton } from "@social-supermarket/social-supermarket-components"

const Container = styled.div`
  padding: 20px;
  display: flex;
`
const FormSection = styled.div`
  margin-bottom: 20px;
`
const Left = styled.div`
  flex: 1;
`
const Right = styled.div`
  flex: 1;
`
const QuantityInput = styled.input`
  width: 90px;
`
const Selection = styled.div`
  background: white;
  margin-bottom: 10px;
  padding: 10px;
  width: 500px;
  position: relative;
`

const Close = styled.div`
  cursor: pointer;
  width: 20px;
  height: 20px;
  user-select: none;
  position: absolute;
  top: 10px;
  right: 10px;

  svg {
    width: 20px;
    height: 20px;
  }
  path {
    fill: ${colors.darkGrey};
  }
`

interface Props {}

let timeout = null

const AdminImpactPage: FC<Props> = () => {
  const { token } = useContext(LoginContext)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const [includeDummyBenchmarking, setIncludeDummyBenchmarking] = useState<boolean>(false)
  const [search, setSearch] = useState<string>("")
  const [title, setTitle] = useState<string>("")
  const [logoUrl, setLogoUrl] = useState<string>("")
  const [foundProducts, setFoundProducts] = useState<ImpactProductType[]>([])
  const [selections, setSelections] = useState<SelectionType[]>([])
  const [quantity, setQuantity] = useState<number>(1)

  const handleResultSelect = data => {
    setSelections([...selections, { product: data.result, quantity }])
  }

  const handleSearchChange = async (e, data) => {
    clearTimeout(timeout)
    setSearch(data.value)
    timeout = setTimeout(() => handleSearch(data.value), 500)
  }

  const handleSearch = async value => {
    setIsLoading(true)
    try {
      const products = await getProducts(value)
      setFoundProducts(products)
    } catch (e) {
      console.log(e)
    } finally {
      setIsLoading(false)
    }
  }

  const handleRemove = (index: number) => {
    const newArray = [...selections]
    newArray.splice(index, 1)
    setSelections(newArray)
  }

  const handleSubmit = async () => {
    setIsSubmitting(true)
    const key = await generateAdhocReportData(token, selections, title, includeDummyBenchmarking)
    try {
      await downloadReport(token, key, title, logoUrl, includeDummyBenchmarking)
    } catch (e) {
      alert(e)
    } finally {
      setIsSubmitting(false)
      console.log("KEY IS ", key)
    }
  }

  return (
    <Container>
      <Spinner isLoading={isSubmitting} label={"Downloading Report..."} />
      <Left>
        <FormSection>
          <div>Report Title:</div>
          <input type={"string"} value={title} onChange={event => setTitle(event.target.value)} />
        </FormSection>
        <FormSection>
          <div>Logo Url:</div>
          <input
            type={"string"}
            value={logoUrl}
            onChange={event => setLogoUrl(event.target.value)}
          />
        </FormSection>
        <FormSection>
          <div>Include Dummy Benchmarking Pages:</div>
          <Checkbox
            checked={includeDummyBenchmarking}
            partiallyChecked={false}
            onChange={() => setIncludeDummyBenchmarking(!includeDummyBenchmarking)}
          />
        </FormSection>
        <FormSection>
          <div>Quantity:</div>
          <QuantityInput
            type={"number"}
            value={quantity}
            onChange={e => setQuantity(e.target.value)}
          />
        </FormSection>
        <FormSection>
          <label>Product:</label>
          <Search
            loading={isLoading}
            placeholder={"Start typing"}
            onResultSelect={(e, data) => handleResultSelect(data)}
            onSearchChange={handleSearchChange}
            results={foundProducts}
            value={search}
            resultRenderer={p => (
              <div>
                {p.name} [{p.code}]
              </div>
            )}
          />
        </FormSection>
        <div>
          Selections:
          {selections.map((selection, index) => (
            <Selection key={`${index}-${selection.product.name}`}>
              <div>
                <div>
                  <b>Name</b>
                </div>
                {selection.product.name}
              </div>
              <div>
                <div>
                  <b>Product group code</b>
                </div>
                {selection.product.code}
              </div>
              <div>
                <div>
                  <b>Quantity</b>
                </div>
                {selection.quantity}
              </div>
              <Close onClick={() => handleRemove(index)}>
                <CloseIcon />
              </Close>
            </Selection>
          ))}
        </div>
      </Left>

      <Right>
        <PrimaryButton slim onClick={handleSubmit} disabled={selections.length === 0 || !title}>
          Download Report
        </PrimaryButton>
      </Right>
    </Container>
  )
}

export interface ImpactProductType {
  name: string
  code: string
}
export interface SelectionType {
  product: ImpactProductType
  quantity: number
}

export const getProducts = async (search: string): Promise<ImpactProductType[]> => {
  const response = await api.getAirtable(
    `https://api.airtable.com/v0/appSvOYVhMHdUMIJ3/Product%20Groups?filterByFormula=OR(SEARCH("${search.toLowerCase()}",LOWER({Name})),SEARCH("${search.toLowerCase()}",LOWER({Product%20Group%20Code})))`
  )

  return response.records.map(record => ({
    name: record.fields.Name,
    code: record.fields["Product Group Code"],
  }))
}

export default AdminImpactPage
