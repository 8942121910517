import * as React from "react"
import { FC, useContext, useEffect, useState } from "react"
import styled from "styled-components"
import { LoginContext, Spinner } from "@social-supermarket/social-supermarket-components"
import { getVendors } from "../../../api/platform-admin/vendorApi"
import { AgGridColumn, AgGridReact } from "ag-grid-react"
import { GridReadyEvent, RowClickedEvent } from "ag-grid-community/dist/lib/events"
import { GridApi } from "ag-grid-community"
import he from "he"
import TrueFalseRenderer from "../../../components/grid/TrueFalseRenderer"
import VendorAdminPage from "./VendorAdminPage"
import LinkButton from "../../../components/button/LinkButton"
import useQueryString from "../../../hooks/useQueryString"
import { VendorType } from "@social-supermarket/social-supermarket-components"

const Container = styled.div`
  margin: 10px;

  .ag-row {
    cursor: pointer;
  }
`

const BackButton = styled(LinkButton)`
  font-size: 1.5em;
  margin-bottom: 20px;
  margin-top: 10px;
`

let gridApi: GridApi = null

interface Props {}

const VendorsAdminPage: FC<Props> = () => {
  const { token } = useContext(LoginContext)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [vendors, setVendors] = useState<VendorType[]>(null)
  const [selectedVendorSlug, setSelectedVendorSlug] = useQueryString("vendor", "")
  const selectedVendor =
    vendors && selectedVendorSlug
      ? vendors.find(vendor => vendor.slug === selectedVendorSlug)
      : null

  useEffect(() => {
    init()
  }, [])

  const init = async () => {
    try {
      setIsLoading(true)
      const vendors = await getVendors(token)
      setVendors(vendors)
    } catch (e) {
      console.log(e)
    } finally {
      setIsLoading(false)
    }
  }

  const replaceVendor = newVendor => {
    const index = vendors.findIndex(v => v.ID === newVendor.ID)
    const newVendors = [...vendors]
    newVendors[index] = newVendor
    setVendors([...newVendors])
    setSelectedVendorSlug(newVendor.slug)
  }

  const handleGridReady = (gridReady: GridReadyEvent) => {
    gridApi = gridReady.api
    gridReady.api.sizeColumnsToFit()

    gridReady.columnApi.applyColumnState({
      state: [
        {
          colId: "isEnabled",
          sort: "desc",
        },
      ],
      defaultState: { sort: null },
    })
  }

  const handleRowClicked = (event: RowClickedEvent) => {
    setSelectedVendorSlug(event.data.slug)
  }

  return (
    <Container className="ag-theme-balham">
      <Spinner isLoading={isLoading} />
      {selectedVendor && (
        <BackButton onClick={() => setSelectedVendorSlug(null)}>{"< Back"}</BackButton>
      )}
      {selectedVendor && <VendorAdminPage vendor={selectedVendor} replaceVendor={replaceVendor} />}
      {vendors && !selectedVendor && (
        <div>
          <AgGridReact
            rowData={vendors}
            onGridReady={handleGridReady}
            rowSelection={"single"}
            onRowClicked={handleRowClicked}
            suppressCellSelection={true}
            domLayout={"autoHeight"}
            defaultColDef={{
              sortable: true,
              filter: true,
              resizable: true,
            }}
            frameworkComponents={{
              trueFalseRenderer: TrueFalseRenderer,
            }}
          >
            <AgGridColumn field="ID" />
            <AgGridColumn
              field="name"
              valueFormatter={e => he.decode(e.value)}
              headerName={"Vendor Name"}
            />
            <AgGridColumn field="slug" headerName={"Vendor Slug"} />
            <AgGridColumn
              field="isPlatformEnabled"
              cellRenderer="trueFalseRenderer"
              headerName={"Enabled On Platform"}
            />
            <AgGridColumn
              field="platformHandlesStock"
              cellRenderer="trueFalseRenderer"
              headerName={"Manage Stock"}
            />
          </AgGridReact>
        </div>
      )}
    </Container>
  )
}

export default VendorsAdminPage
