import { getConfig } from "../../../config/index"
import { api, ProductType, VendorType } from "@social-supermarket/social-supermarket-components"

export const getVendors = async (token: string): Promise<VendorType[]> => {
  return api.platformGet(
    `${getConfig().baseUrl}/wp-json/social-supermarket/v1/platform/vendors/get-vendors`,
    token
  )
}

export const getVendorProducts = async (
  token: string,
  vendorId: number
): Promise<ProductType[]> => {
  return api.platformGet(
    `${
      getConfig().baseUrl
    }/wp-json/social-supermarket/v1/platform/vendors/get-vendor-products?vendorId=${vendorId}`,
    token
  )
}

export const toggleVendor = async (
  token: string,
  vendorId: number,
  enabled: boolean
): Promise<VendorType> => {
  return api.platformPost(
    `${getConfig().baseUrl}/wp-json/social-supermarket/v1/platform/vendors/toggle-vendor`,
    {
      vendorId,
      enabled,
    },
    token
  )
}

export const toggleVendorProduct = async (
  token: string,
  productId: number,
  enabled: boolean
): Promise<ProductType> => {
  return api.platformPost(
    `${getConfig().baseUrl}/wp-json/social-supermarket/v1/platform/vendors/toggle-product`,
    {
      productId,
      enabled,
    },
    token
  )
}

export const toggleVendorHandlesStock = async (
  token: string,
  vendorId: number,
  handlesStock: boolean
): Promise<VendorType> => {
  return api.platformPost(
    `${
      getConfig().baseUrl
    }/wp-json/social-supermarket/v1/platform/vendors/toggle-vendor-handles-stock`,
    {
      vendorId,
      handlesStock,
    },
    token
  )
}
