import * as React from "react"
import { FC } from "react"
import styled from "styled-components"
import Header from "../../components/header/Header"
import VendorsAdminPage from "./vendors/VendorsAdminPage"
import useQueryString from "../../hooks/useQueryString"
import AdminCustomersPage from "./customers/AdminCustomersPage"
import AdminPaymentsPage from "./payments/AdminPaymentsPage"
import AdminImpactPage from "./impact/AdminImpactPage"
import { colors } from "@social-supermarket/social-supermarket-components"
import SideBarItem from "../../components/side-bar/SideBarItem"

const Container = styled.div`
  background-color: ${colors.lightGray};
`
const Page = styled.div`
  padding-left: 200px;
  flex: 1;
  width: 100vw;
  min-height: calc(100vh - 50px);
`

const SidebarContainer = styled.div`
  width: 200px;
  background-color: white;

  position: fixed;
  height: calc(100vh - 50px);

  display: flex;
  flex-direction: column;
  padding: 10px 0;
`
const BottomContainer = styled.div`
  padding-top: 50px;
  display: flex;
  width: 100vw;
`

interface Props {}

const pages = {
  dashboard: {
    label: "Dashboard",
    component: () => <div>dashboard</div>,
    icon: "dashboard",
  },
  vendors: {
    label: "Vendors",
    component: VendorsAdminPage,
    icon: "box",
  },
  customers: {
    label: "Customers",
    component: AdminCustomersPage,
    icon: "list",
  },
  payments: {
    label: "Payments",
    component: AdminPaymentsPage,
    icon: "list",
  },
  impact: {
    label: "Impact",
    component: AdminImpactPage,
    icon: "list",
  },
}

const AdminPage: FC<Props> = () => {
  const [page, setPage] = useQueryString("page", "dashboard")
  const Component = pages[page].component
  return (
    <Container>
      <Header />
      <BottomContainer>
        <SidebarContainer>
          {Object.keys(pages).map(pageKey => (
            <SideBarItem
              key={pageKey}
              onClick={() => setPage(pageKey)}
              label={pages[pageKey].label}
              icon={pages[pageKey].icon}
              selected={pageKey === page}
            />
          ))}
        </SidebarContainer>
        <Page>
          <Component />
        </Page>
      </BottomContainer>
    </Container>
  )
}

export default AdminPage
