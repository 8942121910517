import * as React from "react"
import { FC, useContext, useState } from "react"
import styled from "styled-components"
import TrueFalseRenderer from "../../../components/grid/TrueFalseRenderer"
import { AgGridColumn, AgGridReact } from "ag-grid-react"
import { GridReadyEvent } from "ag-grid-community/dist/lib/events"
import { GridApi } from "ag-grid-community"
import {
  LoginContext,
  paymentApi,
  PaymentType,
  PrimaryButton,
  Spinner,
} from "@social-supermarket/social-supermarket-components"
import { getConfig } from "../../../../config"

const Container = styled.div`
  margin: 5px;
  background-color: white;
`

const Controls = styled.div`
  padding: 10px;
`

const XeroInvoiceRenderer = ({ value }) => {
  return (
    <Container>
      <a
        target="_blank"
        href={`https://go.xero.com/AccountsReceivable/View.aspx?InvoiceID=${value}`}
      >
        {value}
      </a>
    </Container>
  )
}

let gridApi: GridApi = null

interface Props {
  payments: PaymentType[]
}

const AdminAllPaymentsList: FC<Props> = ({ payments }) => {
  const { token } = useContext(LoginContext)
  const [selected, setSelected] = useState<PaymentType[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const handleGridReady = (gridReady: GridReadyEvent) => {
    gridApi = gridReady.api
    gridReady.api.sizeColumnsToFit()
  }

  const handleSelectionChanged = () => {
    setSelected(gridApi?.getSelectedRows() || [])
  }

  const handleGenerate = async () => {
    try {
      setIsLoading(true)
      const data = await paymentApi.generateXeroInvoice(
        getConfig().baseUrl,
        token,
        selected.map(payment => payment.ID)
      )
      // console.log(data.Id)
      window
        .open(
          `https://go.xero.com/AccountsReceivable/Edit.aspx?InvoiceID=${data.Invoices[0].InvoiceID}`,
          "_blank"
        )
        .focus()
    } catch (e) {
      console.log(`Unexpected error, please contact support: ${e}`)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Container>
      <Spinner isLoading={isLoading} />
      <Controls>
        <PrimaryButton disabled={!selected.length} onClick={handleGenerate}>
          Generate Xero Invoice
        </PrimaryButton>
      </Controls>
      <div className="ag-theme-balham">
        <AgGridReact
          rowData={payments}
          onGridReady={handleGridReady}
          rowSelection={"multiple"}
          suppressCellSelection={true}
          domLayout={"autoHeight"}
          defaultColDef={{
            sortable: true,
            filter: true,
            resizable: true,
          }}
          onSelectionChanged={handleSelectionChanged}
          frameworkComponents={{
            trueFalseRenderer: TrueFalseRenderer,
            xeroInvoiceRenderer: XeroInvoiceRenderer,
          }}
        >
          <AgGridColumn
            field="ID"
            headerCheckboxSelection={true}
            headerCheckboxSelectionFilteredOnly={true}
            checkboxSelection={true}
          />
          <AgGridColumn field="amount" />
          <AgGridColumn field="date" />
          <AgGridColumn field="name" />
          <AgGridColumn field="email" />
          <AgGridColumn field="orderId" />
          <AgGridColumn field="email" />
          <AgGridColumn field="status" />
          <AgGridColumn
            field="xeroInvoiceId"
            headerName={"Xero Invoice"}
            cellRenderer={"xeroInvoiceRenderer"}
          />
        </AgGridReact>
      </div>
    </Container>
  )
}

export default AdminAllPaymentsList
